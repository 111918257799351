<!-- 酒店管理-订单管理-酒店订单 -->

<template>
  <div class="order-hotel main-cnt">
    <div class="title">
      订单列表
      <div class="refresh-icon" @click="refreshTable">
        <i class="iconfont icon-a-lujing2211"></i>刷新订单
      </div>
    </div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :needExport="true"
        :needPrint="true" :filters="filters" :groupFilters="groupFilters" :ispaging="true"
        :apiName="HotelApi.getHotelOrder" :columns="tableColumns" @detail="openDetailDialog"
        @projectChange="projectChange" @export="handleExport" @print="handlePrint">
        <template #pay_status="{ row }">
          <div class="status-text" :class="orderStatusColors(row.pay_status, 'pay')">{{ row.pay_status_text }}</div>
        </template>

        <template #work_status="{ row }">
          <div class="status-text" :class="orderStatusColors(row.work_status, 'work')">
            {{ row.work_status_text }}</div>
        </template>
        <!-- 发起退款 -->
        <template #startrRefund="{ row }">
          <template v-if="row.is_init_refund == 1 && authData.indexOf('h_ZLY1B2aJ1VwOgDzYYGXUFjNIh58k') != -1">
            <el-button class="black-font-btn" @click="openStartrRefundDialog(row)">发起退款</el-button>
          </template>
        </template>
        <!-- 确认退款 -->
        <template #sureRefund="{ row }">
          <template v-if="row.is_confirm_refund == 1 && authData.indexOf('h_ETrMwWza4qPH3fEihBsTOHe0FhSX') != -1">
            <el-button class="theme-font-btn" @click="openSureRefundDialog(row)">确认退款</el-button>
          </template>
        </template>
        <!-- 取消退款 -->
        <template #cancelRefund="{ row }">
          <template v-if="row.is_cancel_refund == 1 && authData.indexOf('h_62aLxCxovfel0OuSQACgaGvDqN9J') != -1">
            <el-popconfirm title="确定要取消该订单退款吗?" @confirm="onConfirmRefund(row)">
              <template #reference>
                <el-button class="theme-font-btn">取消退款</el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>
        <!-- 确认订单 -->
        <template #sureOrder="{ row }">
          <template v-if="authData.indexOf('h_3H77F45ml4XpGIaLBkB0STAQum9q') != -1 && row.is_confirm_order == 1">
            <el-button class="theme-font-btn" @click="openSureOrderDialog(row)">确认订单</el-button>
          </template>
        </template>
        <!-- 入住时间 -->
        <template #checkTime="{ row }">
          <template v-if="authData.indexOf('h_ghzGCMeAa9h7wriT8cUzFV6gafub') != -1 && row.is_change_time_order == 1">
            <el-button class="black-font-btn" @click="openCheckTimeDialog(row)">入住时间</el-button>
          </template>
        </template>
      </common-table>
    </div>

    <!-- 确认订单弹窗 -->
    <confirm-dialog ref="sureOrderDialog" @wConfirm="handleSureOrder" title="确认订单后不可撤回，请谨慎操作">
      <div class="check-time">
        <el-date-picker :clearable="false" v-model="checkIime" type="date" placeholder="入住时间"
          :disabled-date="disabledDate" />
      </div>
    </confirm-dialog>
    <!-- 发起退款 -->
    <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>
    <!-- 确认退款 -->
    <RefundDialog ref="sureRefundRef" @submit="handleSureRefund"></RefundDialog>
    <!-- 订单详情 -->
    <HotelDetailDialog ref="hotelDetailRef" @submit="refreshTable"></HotelDetailDialog>
    <!-- 修改入住时间 -->
    <CheckTimeDialog ref="checkTimeRef" @reloadTable="refreshTable"></CheckTimeDialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, computed } from "vue";
  import { BasicApi, HotelApi, } from "@/plugins/api.js";
  import ConfirmDialog from "@/components/confirm-dialog/confirm-dialog.vue";
  import { ElMessage } from "element-plus";
  import RefundDialog from "../components/RefundDialog.vue";
  import HotelDetailDialog from "../components/HotelDetailDialog.vue";
  import CheckTimeDialog from "../components/CheckTime.vue";
  import { useStore } from "vuex";
  import dayjs from "dayjs";
  import {
    exportExcel,
    print,
    getPayStatusColors,
    getWorkStatusColors,
    getPayStatusOptions,
    getHotelWorkStatusOptions,
    orderStatusColors,
  } from "@/utils/common.js";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  function refreshTable() {
    tableRef.value.tableLoad();
  }
  const payStatusOptions = ref(getPayStatusOptions());
  const payStatusColors = ref(getPayStatusColors());
  const workStatusOptions = ref(getHotelWorkStatusOptions());
  const workStatusColors = ref(getWorkStatusColors());
  const payStatusLabelArr = ref([]);
  function getPayStatusLabelArr() {
    for (var key in payStatusOptions.value) {
      payStatusOptions.value[key] &&
        payStatusLabelArr.value.push({
          label: payStatusOptions.value[key],
          value: +key,
        });
    }
    groupFilters.value[0].options = payStatusLabelArr.value;
  }
  const workStatusLabelArr = ref([]);
  function getWorkStatusLabelArr() {
    for (var key in workStatusOptions.value) {
      workStatusOptions.value[key] &&
        workStatusLabelArr.value.push({
          label: workStatusOptions.value[key],
          value: +key,
        });
    }
    groupFilters.value[1].options = workStatusLabelArr.value;
  }
  onMounted(() => {
    getProjectData();
    tableRef.value.tableLoad();
    getPayStatusLabelArr();
    getWorkStatusLabelArr();
  });

  const filters = ref([
    {
      filterType: "search",
      name: "keyword",
      value: "",
      placeholder: "搜索订单号、酒店、套餐、入住人姓名、手机号、身份证",
    },
    {
      filterType: "select",
      name: "p_id",
      value: "",
      placeholder: "请选择项目",
      options: [],
      lab: "p_name",
      val: "p_id",
      action: "projectChange",
    },
    {
      filterType: "select",
      name: "h_id",
      value: "",
      placeholder: "请选择酒店",
      options: [],
      lab: "h_name",
      val: "h_id",
    },
    {
      filterType: "select",
      name: "buy_type",
      value: "",
      placeholder: "请选择购买类型",
      options: [
        {
          label: "单独购买",
          value: 1,
        },
        {
          label: "活动购买",
          value: 2,
        },
      ],
      lab: "label",
      val: "value",
    },
  ]);
  const groupFilters = ref([
    {
      filterType: "select",
      name: "pay_status",
      value: '',
      label: "支付状态",
      options: [],
      val: "value",
      lab: "label",
      otherOptionsName: "work_status",
      showOtherOptionsValue: 2,
    },
    {
      filterType: "multipleSelect",
      name: "work_status",
      value: [],
      label: "业务状态",
      options: [],
      val: "value",
      lab: "label",
    },
    {
      filterType: "partDaterange",
      name1: "stime",
      value1: "",
      name2: "etime",
      value2: "",
      label: "支付时间",
    },
  ]);
  const tableRef = ref(null);
  const getProjectData = () => {
    BasicApi.projectSelect().then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取项目数据失败！";
        ElMessage.error(msg);
      }
    });
  };

  const projectChange = (obj) => {
    filters.value[2].value = "";
    filters.value[2].options = [];
    tableRef.value.tableLoad();
    if (obj.value) {
      BasicApi.getHotelByProject({ pid: obj.value }).then((res) => {
        if (res.Code === 200) {
          filters.value[2].options = res.Data ? res.Data : [];
        } else {
          let msg = res.Message ? res.Message : "获取酒店数据失败！";
          ElMessage.error(msg);
        }
      });
    }
  };

  const tableColumns = ref([
    {
      prop: "hotel_package_type",
      label: "套餐名称",
      minWidth: 190,
      prop2: "hotel_package",
      type: "connect",
      connectText: "-",
    },
    {
      prop: "hotel_name",
      label: "所属酒店",
      minWidth: 190,
      color: "--text-third-color",
    },
    {
      prop: "realname",
      label: "游客信息",
      minWidth: 170,
      color: "--text-third-color",
      prop2: "mobile",
      type: "connect",
      connectText: "-",
    },
    {
      prop: "sdate",
      label: "入住日期",
      color: "--text-third-color",
      minWidth: 198,
      prop2: "edate",
      type: "connect",
      connectText: "至",
    },
    {
      prop: "pay_money",
      label: "支付金额",
      minWidth: 80,
    },
    {
      prop: "refund_money",
      label: "退款金额",
      minWidth: 80,
      color: "--text-third-color",
    },
    {
      prop: "payway_name",
      label: "支付方式",
      minWidth: 100,
      color: "--text-third-color",
    },
    {
      prop: "pay_status",
      label: "支付状态",
      type: "customRender",
      minWidth: 100,
    },

    {
      prop: "work_status",
      label: "业务状态",
      color: "--text-third-color",
      type: "customRender",
      minWidth: 100,
    },

    {
      prop: "pay_time",
      label: "支付时间",
      color: "--text-third-color",
      minWidth: 150,
    },
    {
      prop: "source_text",
      label: "来源渠道",
      color: "--text-third-color",
      minWidth: 120,
    },
    {
      prop: "ctime",
      label: "下单时间",
      color: "--text-third-color",
      minWidth: 150,
    },

    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 480,
      bottons: [
        {
          name: "发起退款",
          action: "startrRefund",
          type: "customRender",
        },
        {
          name: "确认订单",
          action: "sureOrder",
          type: "customRender",
        },
        {
          name: "确认退款",
          action: "sureRefund",
          type: "customRender",
        },
        {
          name: "取消退款",
          action: "cancelRefund",
          type: "customRender",
        },
        {
          name: "详情",
          action: "detail",
          token: "h_hb5QTlgne2O7Zv6GO2BvBI60MS6u",
        },
        {
          name: "入住时间",
          action: "checkTime",
          type: "customRender",
        },
      ],
    },
  ]);
  // 发起退款
  const initRefundRef = ref(null);
  const currentRefundSn = ref("");
  const openStartrRefundDialog = (row) => {
    currentRefundSn.value = row.sn;
    initRefundRef.value.openDialog("发起退款", row.pay_money);
  };
  function handleInitRefund(data) {
    const parmas = {
      sn: currentRefundSn.value,
      ...data,
    };
    HotelApi.initiateRefundOrder(parmas).then((res) => {
      initRefundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        initRefundRef.value.closeDialog();
        refreshTable();
        ElMessage.success("操作成功");
      } else {
        let msg = res.Message ? res.Message : "操作失败！";
        ElMessage.error(msg);
      }
    });
  }
  // 确认退款
  const sureRefundRef = ref(null);
  function openSureRefundDialog(row) {
    currentRefundSn.value = row.sn;
    sureRefundRef.value.openDialog(
      "确认退款",
      row.pay_money,
      row.confirm_refund_money
    );
  }
  function handleSureRefund(data) {
    const parmas = {
      sn: currentRefundSn.value,
      ...data,
    };
    HotelApi.confirmRefundOrder(parmas).then((res) => {
      sureRefundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        sureRefundRef.value.closeDialog();
        refreshTable();
        ElMessage.success("操作成功");
      } else {
        let msg = res.Message ? res.Message : "操作失败！";
        ElMessage.error(msg);
      }
    });
  }
  // 确认订单
  const checkIime = ref("");
  const sureOrderDialog = ref(null);
  const currentSureOrderSn = ref("");
  function openSureOrderDialog(row) {
    currentSureOrderSn.value = row.sn;
    checkIime.value = row.sdate || dayjs();
    sureOrderDialog.value.show();
  }
  const disabledDate = (value) => {
    return value.getTime() < dayjs(dayjs().format("YYYY-MM-DD")).valueOf();
  };
  function handleSureOrder() {
    sureOrderDialog.value.isLoading = true;
    if (!checkIime.value) {
      ElMessage.error("请选择入住时间！");
      return false;
    }
    const lastTime = dayjs(checkIime.value).format("YYYY-MM-DD");
    HotelApi.confirmHotelOrder({
      sn: currentSureOrderSn.value,
      hotel_time: lastTime,
    }).then((res) => {
      sureOrderDialog.value.isLoading = false;
      if (res.Code === 200) {
        sureOrderDialog.value.close();
        refreshTable();
        ElMessage.success("操作成功");
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  // 酒店订单详情
  const hotelDetailRef = ref(null);
  function openDetailDialog(row) {
    hotelDetailRef.value.openDialog(row.sn, {
      payStatus: {
        options: payStatusOptions.value,
        colors: payStatusColors.value,
      },
      workStatus: {
        options: workStatusOptions.value,
        colors: workStatusColors.value,
      },
    });
  }
  const checkTimeRef = ref(null);
  function openCheckTimeDialog(row) {
    checkTimeRef.value.openDialog(row);
  }

  const propertiesConfig = ref([
    { field: "packageName", displayName: "套餐名称" },
    { field: "hotel_name", displayName: "所属酒店" },
    { field: "touristName", displayName: "游客信息" },
    { field: "checkinDate", displayName: "入住日期" },
    { field: "pay_money", displayName: "支付金额" },
    { field: "refund_money", displayName: "退款金额" },
    { field: "payway_name", displayName: "支付方式" },
    { field: "pay_status_text", displayName: "支付状态" },
    { field: "work_status_text", displayName: "业务状态" },
    { field: "pay_time", displayName: "支付时间" },
    { field: "source_text", displayName: "来源渠道" },
    { field: "ctime", displayName: "下单时间" },
  ]);
  function processData(data) {
    data.length &&
      data.forEach((el) => {
        el.checkinDate = el.sdate + "至" + el.edate;
        el.packageName = el.hotel_package_type + "-" + el.hotel_package;
        el.touristName = el.realname + '-' + el.mobile;
      });
    return data;
  }
  function handlePrint(data) {
    print("酒店订单", processData(data), propertiesConfig.value);
  }
  function handleExport(data) {
    try {
      exportExcel("酒店订单", processData(data), propertiesConfig.value);
      ElMessage.success({
        message: "导出成功！",
      });
    } catch (e) {
      ElMessage.error({
        message: "导出失败！",
      });
    }
  }
  /**
   * 
   * 取消退款确定按钮
   * 
   * **/
  function onConfirmRefund(row) {
    HotelApi.cancelRefundOrder({ sn: row.sn }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("取消退款成功！");
        refreshTable();
      } else {
        let msg = res.Message ? res.Message : "取消退款失败！";
        ElMessage.error(msg);
      }
    });
  }
</script>
<style lang="scss">
  .order-hotel {
    font-family: "Source Han Sans CN";

    .search-input {
      width: 400px !important;
    }

    .verification-dialog {
      .el-form-item {
        margin-bottom: 6px;
      }

      .el-form-item__error {
        display: none;
      }

      .tips {
        color: var(--theme-color);
        font-size: 12px;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .refresh-icon {
        color: var(--theme-color);
        font-weight: normal;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        i {
          margin-right: 4px;
        }
      }
    }

    .el-input-group__append {
      border: none;
      background-color: var(--search-uncheck-bg-color) !important;
      color: var(--text-gray-color);
    }

    .content {
      padding: 15px 20px 20px;
    }

    .check-time {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }

    .font-gray {
      color: var(--text-gray-color);
    }

    .font-999 {
      color: var(--text-third-color);
    }

    .refund-money {
      width: 100%;
      padding: 20px 0;
      background-color: var(--theme-bg-color);
      text-align: center;
      border-radius: 10px;
      margin-bottom: 20px;
      color: var(--text-gray-color);

      >div {
        color: var(--text-color);
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
</style>